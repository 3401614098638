var riot = require('riot');

var TTHeader = require('./TimeTablerCommon/Common/TTHeader.tag');
var TTFooter = require('./TimeTablerCommon/Common/TTFooter.tag');
var TTcommonPopupGeneric = require('./TimeTablerCommon/Common/TTcommonPopupGeneric.tag'); 

var SDHomePage = require('./TimeTablerCommon/Home/SDHomePage.tag'); 
var SDNewHomePage = require('./TimeTablerCommon/Home/SDNewHomePage.tag');
var SDNewHomePageWorkShop = require('./TimeTablerCommon/Home/SDNewHomePageWorkShop.tag');

var Maintenance = require('./TimeTablerCommon/Home/Maintenance.tag');

//var SDAboutUs = require('./TimeTabler/AboutUs/SDAboutUs.tag'); 
var SDContactUs = require('./TimeTabler/AboutUs/SDContactUs.tag');
var SDMedia = require('./TimeTabler/AboutUs/SDMedia.tag'); 

var Login = require('./TimeTablerCommon/Login/TTLogin.tag');
var SDSignUp = require('./TimeTablerCommon/Login/SDSignUp.tag');

var popup = require('./introTags/aboutUs/TTContactUsPopupNotify.tag');
var ManualSectionOne = require('./TimeTabler/CustomerManuals/ManualSectionOne.tag');
var UserAgreementsPage = require('./TimeTabler/UserAgreements/UserAgreementsPage.tag');
var SDExpressPage = require('./TimeTabler/SDExpress/SDExpressPage.tag');
var eventSystemMixin = require('./mixins/eventsystem.js');

riot.mount('*');

var riot = require('riot');
module.exports = riot.tag2('maintenance', '<ttcommonpopupgeneric></TTcommonPopupGeneric><div class="SDHomePage"><div class="SDHomeMenuMainContainer"><div class="SDMenuGroup" style="margin-top: 140px;"><div class="SDMenuHeadingContainer"><div class="SDMenuHeadingIcon"><i class="fa fa-search SchoolDataIconHomePage"></i></div><div class="SDMenuHeadingMaintText"> System offline for Maintenance... </div></div><div class="SDPhaseStepsContainer"><div class="SDHomePageArticleContainer"><div class="ArticleBasicContentMaint"><p> The SchoolData platform is temporarily offline for a System Upgrade. </p><p> The upgrade will only take around 10 minutes. </p><p> We apologise for any inconvenience. </p></div></div></div></div></div></div>', '', '', function(opts) {
        this.mixin('eventSystem');
        this.listMode = true;
        this.AccessAllowed = false;
        this.slideIndex = 0;

        this.on('mount', function () {

        }.bind(this));

        this.on('update', function () {

        }.bind(this));

});

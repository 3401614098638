var riot = require('riot');
module.exports = riot.tag2('sdsignup', '<ttcommonpopupgeneric></TTcommonPopupGeneric><input id="idPagename" type="hidden" value="Home"><div class="SDSignUp"><div id="idForgotPassword" if="{!EmailExpired}"><div class="center"><div class="SignupEmailContainer"><div class="LoginHeader"> Set Your SchoolData Password </div><div class="LoginBody"><div class="LoginBodyEmail"><div class="LoginDetailsInstructions"> Welcome to SchoolData {opts.fullname}! Please set your password below to get started. </div></div><div class="LoginBodyEmail"><input autofocus="autofocus" class="LoginDetailsInputBox" id="idPassword1" name="Password1" maxlength="50" placeholder="Your new Password" type="password" value=""></div><div class="LoginBodyEmail"><input autofocus="autofocus" class="LoginDetailsInputBox" id="idPassword2" name="Password1" maxlength="50" placeholder="Re-type Your new password" type="password" value=""></div><div class="LoginBodyLoginButton"><input type="button" value="Change Password" id="btnlogin" class="LoginGridDetailsSavebutton" onclick="{ChangePassword}"><input type="button" value="Cancel" id="btnlogin" class="LoginGridDetailsCancelbutton" onclick="{ExitThisScreen}"></div></div></div></div></div><div id="idForgotPassword" if="{EmailExpired}"><div class="center"><div class="SignupEmailContainer"><div class="LoginHeader"> Request Password Reset Email Expired </div><div class="LoginBody"><div class="LoginBodyEmail"><div class="LoginDetailsInstructions"><span id="idLoginDetailsInstructionsSpan"></span></div></div><div class="LoginBodyLoginButton"><input type="button" value="Back To SchoolData" id="btnlogin" class="LoginGridDetailsSavebutton" onclick="{ExitThisScreen}"></div></div></div></div></div></div>', '', '', function(opts) {
        this.mixin('eventSystem');

        this.reCAPTCHA_site_key = "";

        this.on('mount', function () {

            this.SignUpOtp = opts.otp;
            this.EmailExpired = opts.emailexpired;
            this.ServerURL = opts.serverurl;
            this.OTPInvalid = (opts.otpinvalid == 'True');

            var ResetMessage = "The Schooldata Staff Access Request email has expired.<br>Please request a new Schooldata Staff Access Request email, by clicking on the following link.<p>";
            if (this.OTPInvalid) {
                ResetMessage = "The Schooldata Staff Access Request email key is invalid.<br>Please check your inbox for a newer Schooldata Staff Access Request email.<p>Alternatively, request a new Schooldata Staff Access Request email by clicking on the following link.<p>";
                this.EmailExpired = true;
            }
            ResetMessage += '<a href = "' + this.ServerURL + '">Send me a Schooldata Staff Access Request Email</a>';

            this.idLoginDetailsInstructionsSpan.innerHTML = ResetMessage;

            this.RequestVerificationToken = $("input[name='__RequestVerificationToken']").val();

            this.update();

        }.bind(this));

        this.idPassword2.addEventListener("keyup", function (event) {

            if (event.keyCode === 13) {

                event.preventDefault();

                this.loginButtonEnter();

            }
        }.bind(this));

        this.ExitThisScreen = function()
        {
            window.location.href = "/";

        }.bind(this)

        this.ChangePassword = function()
        {
            if (this.idPassword1.value !== this.idPassword2.value) {
                this.publish('TTShowCommonPopupGeneric', { Type: 'Alert', Header: 'Schooldata Alert', Message: "Passwords do not Match.<br>Please ensure you type the same password into each field." });
                return;
            }

            this.ChangePasswordProcess();
        }.bind(this)

        this.ChangePasswordProcess = function()
        {

            var RHSigninDTO = {
                '__RequestVerificationToken': this.RequestVerificationToken,
                'otp': this.SignUpOtp,
                'CustPassword': this.idPassword1.value,
                'Status': true,
                'Message': ""
            };

            $.ajax({
                type: "POST",
                url: '/Welcome/SDSignupProcess',
                data: RHSigninDTO,
                cache: false,
                async: true,
                dataType: "json",

                error: function (response) {
                    this.publish('TTShowCommonPopupGeneric', { Type: 'Alert', Header: 'Schooldata Alert', Message: response.Message });
                    this.update();

                }.bind(this),

                success: function (response) {
                    if (response.Status == false) {
                        this.publish('TTShowCommonPopupGeneric', { Type: 'Alert', Header: 'Schooldata Alert', Message: response.Message });
                        return;
                    }

                    window.location.href = "/SDMyloads/Portal";

                }.bind(this)
            });
        }.bind(this)

});

var riot = require('riot');
module.exports = riot.tag2('ttfooter', '<div class="SDMainFooter" riot-style="{!TabGenMgtLinks ? \'\' : \'background-color: red; color: white;\'}"><div class="BuildData"> &copy; 2021 - SchoolData V{Version} - {opts.school}<span show="{AccessLevel >= AdminLevel.TTN}">-{h1}-{w1}</span> - {opts.accesscode} - ServerTime - {opts.servertime} </div><div class="{ShowExtraStats ? \'TableStatsLessPadding\' : \'TableStatsPadding\'}" if="{ShowRecords}"><div if="{!BlockDetails}" style="display: inline-block;"><div class="ExtraStats" if="{ShowExtraStats}">{ExtraStats}</div><button type="button" class="TableStatisticsbutton" if="{ShowExtraStatsButton}" onclick="{ExtraStatsPopup}">Stats</button></div> Table Stats - {Records} - Rows </div></div>', '', '', function(opts) {
        this.mixin('eventSystem');
        this.Constants = require('./../../services/constants.js');
        this.AdminLevel = this.Constants.AdminLevel;
        this.Version = "";
        this.ShowRecords = false;
        this.ShowExtraStats = false;
        this.ShowExtraStatsButton = false;
        this.AccessLevel = 0;
        this.ResizeRefreshBlock = false;
        this.TimeoutHandle = null;
        this.TabGenMgtLinks = false;
        this.IgnoreReLoad = true;

        this.loggedIn = false;
        this.DebugAdminLevel = "";

        this.on('mount', function () {

            this.Version = opts.version;

            if (typeof opts.access !== "undefined")
                this.AccessLevel = parseInt(opts.access, 10);

            var bill = opts.accesscode;

            window.addEventListener('resize', this.ResizeProcess);

            if (opts.compilerenvironment == "DevCompiler")
                this.TabGenMgtLinks = true;

            this.ResizeProcess();

            this.update();

        }.bind(this))

        this.ResizeProcess = function()
        {
            this.h1 = $(window).height();
            this.w1 = $(window).width();

            if (this.TimeoutHandle != null) {
                clearTimeout(this.TimeoutHandle);
            }

            this.TimeoutHandle = setTimeout(this.ReCalcResizeView, 1000);

            this.publish('WindowHeightChanged', { Height: this.h1, Width: this.w1 });

            this.update();
        }.bind(this)

        this.ReCalcResizeView = function()
        {
            this.ResizeRefreshBlock = false;
            if (this.IgnoreReLoad) {
                this.IgnoreReLoad = false;
                return;
            }
            this.publish("RefreshTableData");
        }.bind(this)

        this.subscribe('SetTableStats', function (TableStats)
        {
            this.ShowRecords = true;
            this.Records = TableStats.data.RecordCount;
            this.BlockDetails = TableStats.data.BlockDetails;
            this.ExtraStats = TableStats.data.ExtraStats;
            if (typeof this.ExtraStats != "undefined") {
                if (this.ExtraStats != "") {
                    this.ShowExtraStats = true;
                    if (typeof TableStats.sourceTag.TableName != "undefined") {
                        if (TableStats.sourceTag.TableName.indexOf('grid') != -1)
                            this.ShowExtraStatsButton = true;
                    }
                }
            }

            this.update();
        });

        this.ExtraStatsPopup = function()
        {
            this.publish('FetchGridReportStatisticsSub', { Type: 'grid' });
        }.bind(this)

        this.on("update", function () {
        });

});




var riot = require('riot');
module.exports = riot.tag2('useragreementspage', '<div class="NavContainer"><div class="NavItems"><div class="NavItemText {ShowSection === \'Terms\' ? \'active\' : \'\'}" onclick="{() => ToggleSection(\'Terms\')}">Terms of Service</div><div class="NavItemText {ShowSection === \'Data\' ? \'active\' : \'\'}" onclick="{() => ToggleSection(\'Data\')}">Data</div><div class="NavItemText {ShowSection === \'Privacy\' ? \'active\' : \'\'}" onclick="{() => ToggleSection(\'Privacy\')}">Privacy Policy</div></div></div><div class="ConditionOuterContainer"><div class="ConditionContainer {ShowSection === \'Terms\' ? \'ShowCondition\' : \'HideCondition\'}"><div id="idTermsContainer" class="ConditionHeader"><h1>Terms of Service</h1></div><div class="ConditionTextContainer"><div class="ConditionText"> Coming soon! </div></div></div><div class="ConditionContainer {ShowSection === \'Data\' ? \'ShowCondition\' : \'HideCondition\'}"><div id="idDataContainer" class="ConditionHeader"><h1>Data</h1></div><div class="ConditionTextContainer"><div class="ConditionText"> Coming soon! </div></div></div><div class="ConditionContainer {ShowSection === \'Privacy\' ? \'ShowCondition\' : \'HideCondition\'}"><div id="idTermsContainer" class="ConditionHeader"><h1>Privacy Policy</h1></div><div class="ConditionTextContainer"><div class="ConditionText"> Coming soon! </div></div></div></div>', '', '', function(opts) {
        this.mixin('eventSystem');
        this.ShowSection = 'Terms';

        this.on('mount', function () {

        }.bind(this));

        ToggleSection = (Section) => {
            this.ShowSection = Section;
            this.update();
        }

});